* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
select,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  line-height: 18px;
}
:root {
  --whiteColor: #fff;
  --blueColor: #0095ff;
  --blueColor2: #d6eeff;
  --blueColor4: #85ccff;
  --blueColor5: #4cb5ff;
  --blueColor7: #0076e0;
  --redColor: #f1361d;
  --redColor6: #ed3545;
  --grayColor: #d9dce3;
  --grayColor100: #f7f9fc;
  --grayColor101: #f7f9fc;
  --grayColor102: #cfd5e6;
  --grayColor103: #8893b2;
  --grayColor104: #bfc6db;
  --grayHoverColor: #c4c8d4;
  --grayColor10: #1a1a1a;
  --warmGrayColor1: #faf8f5;
  --warmGrayColor2: #f0eeeb;
  --warmGrayColor3: #e1dfdb;
  --warmGrayColor4: #d0cdc8;
  --warmGrayColor5: #bcb9b3;
  --warmGrayColor6: #938e86;
  --warmGrayColor7: #6b6861;
  --warmGrayColor8: #514e48;
  --warmGrayColor9: #37342f;
  --warmGrayColor10: #1a1814;
  --coldGrayColor: #7d859b;
  --coldGrayColor0: #d9dce3;
  --coldGrayColor1: #f5f6fa;
  --coldGrayColor2: #eaebf1;
  --coldGrayColor5: #adb2c2;
  --coldGrayColor7: #5b6173;
  --coldGrayColor10: #14151a;
  --yellowColor2: #fff2cf;
  --yellowColor6: #ffc723;
  --yellowColor8: #e3a413;
  --yellowColor9: #d18f0a;
  --greenColor6: #00b333;
  --orangeColor6: #ff6a00;
  --textColor: #14151a;
  --inputDisabledBackgroundColor: var(--grayColor101);
  --inputDisabledFontColor: var(--coldGrayColor);
  --inputPlaceholderColor: var(--coldGrayColor);
  --inputActiveBorderColor: var(--blueColor);
  --fontSizeSmall: 14px;
  --fontSizeNormal: 16px;
  --fontSizeLarge: 18px;
  --lineHeightSize: 27px;
  --letterSpacingSize: -0.02px;
  --contentMaxWidth: 1440px;
  --contentMaxWidthV2: 868px;
  --mobileMenuHeight: 64px;
}
:root {
  --pp-color-white: #fff;
  --pp-color-gray-1: #f7f9fc;
  --pp-color-gray-2: #e8ebf4;
  --pp-color-gray-3: #cfd5e6;
  --pp-color-gray-4: #bfc6db;
  --pp-color-gray-5: #8893b2;
  --pp-color-gray-6: #6f7894;
  --pp-color-gray-7: #5a6178;
  --pp-color-gray-8: #2c303b;
  --pp-color-blue-1: #e3efff;
  --pp-color-blue-2: #84bbff;
  --pp-color-blue-3: #4295fa;
  --pp-color-blue-4: #308cfc;
  --pp-color-blue-5: #006cf1;
  --pp-color-blue-6: #004395;
  --pp-color-red-1: #ffebef;
  --pp-color-red-2: #f985a0;
  --pp-color-red-3: #f65177;
  --pp-color-red-4: #f2335f;
  --pp-color-red-5: #da0032;
  --pp-color-orange-1: #fff1de;
  --pp-color-orange-2: #fbc374;
  --pp-color-orange-3: #faaa38;
  --pp-color-orange-4: #fa9e15;
  --pp-color-orange-5: #ed8b00;
  --pp-color-yellow-1: #fff9d9;
  --pp-color-yellow-2: #fbe774;
  --pp-color-yellow-3: #fadc2f;
  --pp-color-yellow-4: #f7d200;
  --pp-color-yellow-5: #e8c100;
  --pp-color-green-1: #e4f5ea;
  --pp-color-green-2: #56d683;
  --pp-color-green-3: #09bd48;
  --pp-color-green-4: #00b53f;
  --pp-color-green-5: #009935;
  --pp-color-violet-1: #ecebff;
  --pp-color-violet-2: #b3aef7;
  --pp-color-violet-3: #928bf4;
  --pp-color-violet-4: #867ef7;
  --pp-color-violet-5: #7268f2;
  --pp-color-cyan-1: #e1f6fc;
  --pp-color-cyan-2: #91d1e4;
  --pp-color-cyan-3: #69b8d0;
  --pp-color-cyan-4: #56a4bc;
  --pp-color-cyan-5: #2b94b4;
  --pp-color-magenta-1: #fff0fa;
  --pp-color-magenta-2: #da89bf;
  --pp-color-magenta-3: #ce52a4;
  --pp-color-magenta-4: #b60f7e;
  --pp-color-magenta-5: #a2006c;
  --pp-color-cold-gray-1: #f5f6fa;
  --pp-color-cold-gray-2: #eaebf1;
  --pp-color-cold-gray-10: #14151a;
  --pp-font-primary-rolor: var(--pp-color-cold-gray-10);
  --pp-small-font-size: 14px;
  --pp-small-line-height: 20px;
  --pp-medium-font-size: 16px;
  --pp-large-font-size: 18px;
  --pp-color-subheading: var(--pp-color-gray-6);
  --pp-color-error: var(--pp-color-red-3);
  --pp-color-font-disabled: var(--pp-color-gray-5);
  --pp-color-placeholder: var(--pp-color-gray-5);
  --pp-color-border: var(--pp-color-gray-3);
  --pp-color-border-hover: var(--pp-color-gray-4);
  --pp-color-border-active: var(--pp-color-blue-3);
  --pp-color-border-error: var(--pp-color-error);
  --pp-color-background: var(--pp-color-white);
  --pp-color-background-disabled: var(--pp-color-gray-1);
  --pp-button-background-color-disabled: var(--pp-color-gray-4);
  --pp-button-blue-background-color: var(--pp-color-blue-3);
  --pp-button-blue-font-color: var(--pp-color-white);
  --pp-button-gray-background-color: var(--pp-color-gray-4);
  --pp-button-gray-font-color: var(--pp-color-white);
  --pp-button-border-radius: 3px;
  --pp-button-large-vertical-padding: 8px;
  --pp-button-large-horizontal-padding: 24px;
  --pp-button-large-line-height: 24px;
  --pp-drawer-max-height: calc(100vh - 100px);
  --pp-field-wrapper-message-error-color: var(--pp-color-error);
  --pp-field-wrapper-message-info-color: var(--pp-color-blue-3);
  --pp-field-wrapper-message-success-color: var(--pp-color-green-3);
  --pp-field-wrapper-message-warning-color: var(--pp-color-orange-3);
  --pp-input-background-color: var(--pp-color-white);
  --pp-input-border-color: var(--pp-color-gray-3);
  --pp-input-placeholder-color: var(--pp-color-gray-5);
  --pp-input-hover-border-color: var(--pp-color-gray-4);
  --pp-input-focus-border-color: var(--pp-color-blue-3);
  --pp-input-disabled-font-color: var(--pp-color-gray-5);
  --pp-input-disabled-background-color: var(--pp-color-gray-1);
  --pp-input-disabled-border-color: var(--pp-color-gray-3);
  --pp-input-error-border-color: var(--pp-color-red-3);
  --pp-input-border-radius: 3px;
  --pp-modal-z-index: 9;
  --pp-select-dropdown-icon-color: var(--pp-color-gray-8);
  --pp-select-dropdown-disabled-icon-color: var(--pp-color-gray-4);
  --pp-select-menu-item-active-background-color: var(--pp-color-blue-3);
  --pp-select-menu-item-active-font-color: var(--pp-color-white);
  --pp-select-menu-border-radius: 3px;
  --pp-select-menu-vertical-margin: 8px;
  --pp-select-menu-vertical-padding: 4px;
  --pp-select-menu-item-vertical-padding: 6px;
  --pp-select-menu-item-horizontal-padding: 8px;
  --pp-switch-unchecked-background-color: var(--pp-color-gray-3);
  --pp-switch-unchecked-background-color-disabled: var(--pp-color-gray-2);
  --pp-switch-checked-background-color-disabled: var(--pp-color-gray-3);
  --pp-switch-circle-background-color-disabled: var(--pp-color-gray-1);
  --pp-switch-circle-icon-background-color-hover: var(--pp-color-gray-3);
  --pp-switch-checked-background-color-blue: var(--pp-color-blue-3);
  --pp-switch-checked-background-color-green: var(--pp-color-green-3);
  --pp-switch-width-normal: 45px;
  --pp-switch-height-normal: 24px;
  --pp-switch-circle-size-normal: 18px;
  --pp-switch-circle-top-normal: 3px;
  --pp-switch-circle-left-normal: 3px;
  --pp-switch-circle-translate-x-normal: 21px;
}
body {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 18px;
}
#root {
  height: 100%;
}
a {
  line-height: var(--lineHeightSize);
  color: var(--blueColor);
  text-decoration: none;
}
a,
h4 {
  letter-spacing: var(--letterSpacingSize);
}

button,
input {
  line-height: normal;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
h1 {
  font-size: 36px;
  line-height: 45px;
  margin: 0 0 30px;
}

.logotype {
  text-align: left;
}

.content,
.logotype {
  padding: 30px 60px 0;
  max-width: 1400px;
  position: relative;
}
.logotype svg {
  width: 208px;
  height: 57px;
}
.content {
  padding-bottom: 100px;
}

.content p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 9px;
}

.content h2 {
  font-size: 34px;
  line-height: 41px;
  font-weight: 700;
  margin-bottom: 30px;
}

.content p + ol,
.content p + ul {
  margin-top: -9px;
}
.content ol ol,
.content ol ul,
.content ul ol,
.content ul ul {
  padding: 0 0 0 20px;
}

.content li {
  list-style: none;
}

.content dl,
.content li {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 9px;
}

.content h3 {
  font-size: 18px;
  line-height: 31px;
  font-weight: 700;
  margin: 21px 0 7px;
}

.content .noBreak {
  white-space: nowrap;
}

.content a {
  border-bottom: 1px solid rgba(23, 155, 179, 0.2);
  color: #179bb3;
  outline: none;
  text-decoration: none;
}

a:hover {
  color: brown;
}
